import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1200,
			xl: 1536,
		},
	},
	palette: {
		background: {
			default: '#fff',
		},
	},
	typography: {
		fontSize: 16,
		fontFamily: ['Montserrat', 'Montserrat-italic', 'Abhaya Libre', 'sans-serif'].join(','),
		h1: {
			fontFamily: 'Abhaya Libre',
		},
	},
	shape: {
		borderRadius: 0,
	},
});
export default theme;
