import { Box, Divider, Grid, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import bottleBackground from '../ui/images/greenBottleBackground.png';
import bottle from '../ui/images/greenBottle.png';
import lupulina from '../ui/images/lupulina.png';
import greenOat from '../ui/images/greenOat.png';
import GradientWaves from '../ui/icons/GradientWaves';
import graphicsRight from '../ui/images/greenAsideRight.png';
import graphicsRightSecond from '../ui/images/greenAsideRight2.png';
import graphicsLeft from '../ui/images/greenAsideLeft.png';
import graphicsLeftTablet from '../ui/images/greenGraphicsLeftTablet.png';

const GreenSection = () => {
	const { breakpoints, typography } = useTheme();
	const [offsetY, setOffsetY] = useState(0);
	const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

	const handleScroll = () => setOffsetY(window.scrollY);
	const handleMouseMove = (event: MouseEvent) => {
		setMousePosition({ x: event.clientX, y: event.clientY });
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		document.addEventListener('mousemove', handleMouseMove);
		return () => {
			document.removeEventListener('mousemove', handleMouseMove);
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<Grid
			component={'section'}
			position={'relative'}
			height={'900px'}
			sx={{
				background: 'linear-gradient(90deg, #217839 0%, #C0D000 49.48%, #217839 100%);',
				[breakpoints.down('lg')]: {
					height: 'auto',
				},
			}}
		>
			<Box
				component={'img'}
				src={graphicsLeft}
				alt={''}
				sx={{
					position: 'absolute',
					top: '5%',
					left: 0,
					zIndex: 101,
					transform: `translateY(-${offsetY * 0.5}px)`,
					[breakpoints.down('lg')]: {
						width: '127px',
						top: '10%',
					},
					[breakpoints.down('md')]: {
						width: '117px',
					},
					[breakpoints.down('sm')]: {
						top: '30%',
						width: '110px',
					},
				}}
			/>
			<Box
				component={'img'}
				src={graphicsLeftTablet}
				alt={''}
				sx={{
					display: 'none',
					position: 'absolute',
					bottom: '-140%',
					left: 0,
					zIndex: 101,
					transform: `translateY(-${offsetY * 0.5}px)`,
					[breakpoints.down('md')]: {
						display: 'block',
					},
					[breakpoints.down('sm')]: {
						display: 'none',
					},
				}}
			/>
			<Box
				component={'img'}
				src={graphicsRight}
				alt={''}
				sx={{
					position: 'absolute',
					top: '120%',
					right: 0,
					zIndex: 101,
					transform: `translateY(-${offsetY * 0.5}px)`,
					[breakpoints.down('lg')]: {
						top: '95%',
					},
					[breakpoints.down('sm')]: {
						top: '85%',
						width: '130px',
					},
				}}
			/>
			<Box
				zIndex={104}
				component={'img'}
				src={graphicsRightSecond}
				alt={''}
				sx={{
					position: 'absolute',
					bottom: '-150%',
					right: 0,
					transform: `translateY(-${offsetY * 0.5}px)`,
					[breakpoints.down('lg')]: {
						display: 'none',
					},
				}}
			/>
			<Box
				width={'100%'}
				position={'absolute'}
				top={'-40px'}
				sx={{
					'& svg': {
						width: '100%',
						height: '100%',
					},
					[breakpoints.down('sm')]: {
						top: '-30px',
					},
				}}
			>
				<GradientWaves />
			</Box>
			<Grid
				zIndex={100}
				component={'section'}
				display={'flex'}
				flexDirection={'row-reverse'}
				justifyContent={'space-between'}
				gap={'54px'}
				px={'115px'}
				pt={'100px'}
				mb={'80px'}
				width={'100%'}
				height={'100%'}
				sx={{
					[breakpoints.down('xl')]: {
						pl: '80px',
						gap: '12px',
					},
					[breakpoints.down('lg')]: {
						pt: '20px',
						pr: '48px',
						pl: '20px',
					},
					[breakpoints.down('md')]: {
						mx: '0',
					},
					[breakpoints.down('sm')]: {
						pt: '100px',
						px: '25px',
						flexDirection: 'column-reverse',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
					},
				}}
			>
				<Grid
					display={'flex'}
					flexDirection={'column'}
					alignItems={'flex-start'}
					width={'695px'}
					gap={'24px'}
					sx={{
						[breakpoints.down('lg')]: {
							width: '425px',
						},
						[breakpoints.down('sm')]: {
							width: '100%',
							mt: '400px',
						},
					}}
				>
					<Typography
						color={'#fff'}
						fontSize={'30px'}
						fontWeight={'700'}
						lineHeight={'36px'}
						letterSpacing={'-0.45px'}
						textAlign={'center'}
						bgcolor={'#005329'}
						p={'16px 30px'}
						alignSelf={'center'}
						width={'100%'}
						zIndex={100}
						sx={{
							whiteSpace: 'nowrap',
							[breakpoints.down('lg')]: {
								fontSize: '19px',
								lineHeight: '36px',
								letterSpacing: '-0.3px',
								px: '14px',
								width: '425px',
							},
							[breakpoints.down('sm')]: {
								width: '328px',
								fontSize: '14px',
								lineHeight: '36px',
								letterSpacing: '-0.21px',
							},
						}}
					>
						American Pale Ale z owsem i lupuliną
					</Typography>
					<Box
						display={'flex'}
						gap={'24px'}
						alignItems={'center'}
						sx={{
							[breakpoints.down('lg')]: {
								flexDirection: 'column',
							},
							[breakpoints.down('md')]: {
								width: '100%',
							},
						}}
					>
						<Typography
							color={'#003A1D'}
							fontSize={'18px'}
							fontWeight={'400'}
							lineHeight={'30px'}
							letterSpacing={'-0.27px'}
							textAlign={'center'}
							sx={{
								[breakpoints.down('sm')]: {
									fontSize: '16px',
								},
							}}
						>
							Piwo pełne, zrównoważone, ze{' '}
							<Box
								component={'br'}
								sx={{
									[breakpoints.down('lg')]: {
										display: 'none',
									},
								}}
							/>
							średnią{' '}
							<Box
								component={'br'}
								display={'none'}
								sx={{
									[breakpoints.down('lg')]: {
										display: 'block',
									},
								}}
							/>
							i przyjemną goryczką oraz bogatym{' '}
							<Box
								component={'br'}
								display={'none'}
								sx={{
									[breakpoints.down('sm')]: {
										display: 'block',
									},
								}}
							/>
							aromatem{' '}
							<Box
								component={'br'}
								display={'none'}
								sx={{
									[breakpoints.down('lg')]: {
										display: 'block',
									},
									[breakpoints.down('sm')]: {
										display: 'none',
									},
								}}
							/>
							kilku odmian chmielu.
						</Typography>
						<Box
							display={'flex'}
							justifyContent={'space-between'}
							alignItems={'center'}
							sx={{
								[breakpoints.down('lg')]: {
									width: '425px',
									justifyContent: 'center',
									'& div': {
										width: '33%',
										textAlign: 'center',
									},
								},
								[breakpoints.down('md')]: {
									width: '100%',
								},
							}}
						>
							<Box
								display={'flex'}
								flexDirection={'column'}
								alignItems={'center'}
								gap={'16px'}
								borderLeft={'1px dotted #002251'}
								px={'16px'}
								my={'16px'}
								sx={{
									[breakpoints.down('lg')]: {
										gap: '8px',
									},
								}}
							>
								<Typography
									color={'#003A1D'}
									fontSize={'32px'}
									fontWeight={'600'}
									letterSpacing={'-0.48px'}
									textAlign={'center'}
									mt={'8px'}
									sx={{
										[breakpoints.down('sm')]: {
											fontSize: '24px',
										},
									}}
								>
									13
									<Box
										component={'span'}
										sx={{
											[breakpoints.down('lg')]: {
												display: 'none',
											},
										}}
									>
										,0
									</Box>
									%
								</Typography>
								<Typography
									color={'#003A1D'}
									fontSize={'14px'}
									fontWeight={'400'}
									letterSpacing={'-0.21px'}
									textAlign={'center'}
									sx={{
										[breakpoints.down('sm')]: {
											fontSize: '12px',
										},
									}}
								>
									Ekstrakt
								</Typography>
							</Box>
							<Box
								display={'flex'}
								flexDirection={'column'}
								gap={'16px'}
								borderLeft={'1px dotted #002251'}
								px={'16px'}
								my={'16px'}
								sx={{
									[breakpoints.down('lg')]: {
										gap: '8px',
									},
								}}
							>
								<Typography
									color={'#003A1D'}
									fontSize={'32px'}
									fontWeight={'600'}
									letterSpacing={'-0.48px'}
									textAlign={'center'}
									mt={'8px'}
									sx={{
										[breakpoints.down('sm')]: {
											fontSize: '24px',
										},
									}}
								>
									5,6%
								</Typography>
								<Typography
									color={'#003A1D'}
									fontSize={'14px'}
									fontWeight={'400'}
									letterSpacing={'-0.21px'}
									textAlign={'center'}
									sx={{
										[breakpoints.down('sm')]: {
											fontSize: '12px',
										},
									}}
								>
									Alkohol
								</Typography>
							</Box>
							<Box
								display={'flex'}
								flexDirection={'column'}
								gap={'16px'}
								borderLeft={'1px dotted #002251'}
								borderRight={'1px dotted #002251'}
								px={'16px'}
								my={'16px'}
								sx={{
									[breakpoints.down('lg')]: {
										gap: '8px',
									},
								}}
							>
								<Typography
									color={'#003A1D'}
									fontSize={'32px'}
									fontWeight={'600'}
									letterSpacing={'-0.48px'}
									mt={'8px'}
									sx={{
										[breakpoints.down('sm')]: {
											fontSize: '24px',
										},
									}}
								>
									4/10
								</Typography>
								<Typography
									color={'#003A1D'}
									fontSize={'14px'}
									fontWeight={'400'}
									letterSpacing={'-0.21px'}
									sx={{
										[breakpoints.down('sm')]: {
											fontSize: '12px',
										},
									}}
								>
									Goryczka
								</Typography>
							</Box>
						</Box>
					</Box>
					<Grid
						display={'flex'}
						flexDirection={'column'}
						gap={'24px'}
						border={'1px solid #005329'}
						width={'695px'}
						position={'relative'}
						sx={{
							borderImage: 'linear-gradient(#005329 8%, transparent 0 85%,#005329 0) 2',
							[breakpoints.down('lg')]: {
								borderImage: 'linear-gradient(#005329 24%, transparent 0 90%,#005329 0) 2',
								width: '425px',
							},
							[breakpoints.down('md')]: {
								width: '100%',
							},
						}}
					>
						<Box
							component={'aside'}
							height={'70px'}
							width={'1px'}
							position={'absolute'}
							right={'-1px'}
							borderRight={'1px solid #005329'}
							sx={{
								[breakpoints.down('lg')]: {
									height: '155px',
								},
								[breakpoints.down('md')]: {
									height: '45%',
								},
							}}
						/>
						<Box
							component={'aside'}
							height={'100px'}
							width={'1px'}
							position={'absolute'}
							left={'-1px'}
							bottom={'0'}
							borderLeft={'1px solid #005329'}
							sx={{
								[breakpoints.down('lg')]: {
									height: '100px',
								},
								[breakpoints.down('md')]: {
									height: '25%',
								},
							}}
						/>
						<Box
							sx={{
								position: 'absolute',
								inset: '0',
								m: '0 auto',
								width: '328px',
								top: '-24px',
								[breakpoints.down('sm')]: {
									width: '300px',
								},
							}}
						>
							<Typography
								bgcolor={'#005329'}
								display={'inline-flex'}
								p={'8px 34px'}
								justifyContent={'center'}
								alignItems={'center'}
								gap={'10px'}
								sx={{
									color: '#FFF',
									fontFamily: typography.h1.fontFamily,
									fontSize: '30px',
									fontWeight: '800',
									lineHeight: '30px',
									letterSpacing: '-0.45px',
									[breakpoints.down('lg')]: {
										fontSize: '24px',
										width: '100%',
									},
									[breakpoints.down('sm')]: {
										px: '16px',
									},
								}}
							>
								Wyjątkowe składniki:
							</Typography>
						</Box>
						<Box
							display={'flex'}
							justifyContent={'space-between'}
							alignItems={'center'}
							gap={'8px'}
							m={'40px 40px 0 40px'}
							position={'relative'}
							sx={{
								[breakpoints.down('lg')]: {
									mx: '17px',
								},
							}}
						>
							<Box
								component={'img'}
								src={lupulina}
								alt={''}
								position={'absolute'}
								left={'-90px'}
								sx={{
									[breakpoints.down('lg')]: {
										width: '66px',
										top: '25px',
										left: '-65px',
									},
								}}
							/>
							<Typography
								textAlign={'center'}
								color={'#003A1D'}
								fontSize={'50px'}
								fontWeight={'800'}
								lineHeight={'30px'}
								letterSpacing={'-0.75px'}
								fontFamily={typography.h1.fontFamily}
								sx={{
									[breakpoints.down('lg')]: {
										fontSize: '32px',
										letterSpacing: '-0.48px',
										whiteSpace: 'nowrap',
									},
									[breakpoints.down('sm')]: {
										fontSize: '20px',
										letterSpacing: '-0.3px',
									},
								}}
							>
								Lupulina
							</Typography>
							<Typography
								color={'#003A1D'}
								fontSize={'18px'}
								fontWeight={'400'}
								lineHeight={'21px'}
								letterSpacing={'-0.27px'}
								sx={{
									[breakpoints.down('sm')]: {
										fontSize: '14px',
										'& br': {
											display: 'none',
										},
									},
								}}
							>
								Jest jedną z wielu{' '}
								<Box
									component={'br'}
									display={'none'}
									sx={{
										[breakpoints.down('lg')]: {
											display: 'block',
										},
									}}
								/>
								cennych substancji <Box component={'br'} />
								zawartych w chmielu.{' '}
								<Box
									component={'br'}
									display={'none'}
									sx={{
										[breakpoints.down('lg')]: {
											display: 'block',
										},
									}}
								/>
								<Typography
									component={'span'}
									fontWeight={'600'}
									sx={{
										[breakpoints.down('sm')]: {
											fontSize: '14px',
										},
									}}
								>
									Działa relaksacyjnie.
								</Typography>
							</Typography>
						</Box>
						<Divider
							sx={{
								mx: '100px',
								alignItems: 'center',
								borderColor: '#005329',
								[breakpoints.down('lg')]: {
									mx: '17px',
								},
							}}
						/>
						<Box
							display={'flex'}
							justifyContent={'space-between'}
							alignItems={'center'}
							m={'0 40px 40px 40px'}
							position={'relative'}
							sx={{
								[breakpoints.down('lg')]: {
									mx: '17px',
									mb: '17px',
									gap: '8px',
								},
							}}
						>
							<Typography
								color={'#003A1D'}
								fontSize={'18px'}
								fontWeight={'400'}
								letterSpacing={'-0.27px'}
								textAlign={'right'}
								sx={{
									[breakpoints.down('lg')]: {
										lineHeight: '21px',
									},
									[breakpoints.down('sm')]: {
										fontSize: '14px',
										'& br': {
											display: 'none',
										},
									},
								}}
							>
								Jest pełen wartości odżywczych.{' '}
								<Box
									component={'br'}
									display={'none'}
									sx={{
										[breakpoints.down('lg')]: {
											display: 'block',
										},
									}}
								/>
								To{' '}
								<Typography
									component={'span'}
									color={'#003A1D'}
									fontSize={'18px'}
									fontWeight={'600'}
									letterSpacing={'-0.27px'}
									sx={{
										[breakpoints.down('sm')]: {
											fontSize: '14px',
										},
									}}
								>
									źródło błonnika,{' '}
									<Box
										component={'br'}
										sx={{
											[breakpoints.down('lg')]: {
												display: 'none',
											},
										}}
									/>
									minerałów{' '}
									<Box
										component={'br'}
										display={'none'}
										sx={{
											[breakpoints.down('lg')]: {
												display: 'block',
											},
										}}
									/>
									i witamin.
								</Typography>
							</Typography>
							<Typography
								color={'#003A1D'}
								fontFamily={typography.h1.fontFamily}
								fontSize={'50px'}
								fontWeight={'800'}
								lineHeight={'30px'}
								letterSpacing={'-0.75px'}
								sx={{
									[breakpoints.down('lg')]: {
										fontSize: '32px',
										letterSpacing: '-0.48px',
									},
									[breakpoints.down('sm')]: {
										fontSize: '20px',
										letterSpacing: '-0.3px',
									},
								}}
							>
								Owies
							</Typography>
							<Box
								component={'img'}
								src={greenOat}
								alt={''}
								position={'absolute'}
								right={'-110px'}
								top={'-65px'}
								sx={{
									[breakpoints.down('lg')]: {
										transform: 'rotate(-10deg)',
										width: '80px',
										top: '0px',
										right: '-70px',
									},
									[breakpoints.down('sm')]: {
										transform: 'rotate(-30deg)',
										width: '65px',
										top: '-30px',
										right: '-50px',
									},
								}}
							/>
						</Box>
					</Grid>
					<Box
						display={'flex'}
						flexDirection={'column'}
						gap={'24px'}
					>
						<Typography
							color={'#003A1D'}
							fontSize={'18px'}
							fontWeight={'400'}
							lineHeight={'30px'}
							letterSpacing={'-0.27px'}
							textAlign={'center'}
							sx={{
								[breakpoints.down('lg')]: {
									fontSize: '16px',
									letterSpacing: '-0.24px',
								},
								[breakpoints.down('sm')]: {
									fontSize: '14px',
									lineHeight: '21px',
									letterSpacing: '-0.21px',
								},
							}}
						>
							<Typography
								component={'span'}
								fontWeight={'600'}
								sx={{
									[breakpoints.down('sm')]: {
										fontSize: '14px',
										lineHeight: '21px',
										letterSpacing: '-0.21px',
									},
								}}
							>
								Surowce:
							</Typography>{' '}
							woda, słód jęczmienny (pilzneński, wiedeński, monachijski), słód pszeniczny, płatki owsiane, chmiel
							(Cascade, Columbus, Idaho, Lemondrop, Magnum, Marynka, Sabro), drożdże (górnej fermentacji US-05),
							lupulina.
						</Typography>
					</Box>
				</Grid>
				<Box
					position={'relative'}
					component={'aside'}
					sx={{
						width: '328px',
						m: '0',
						[breakpoints.down('md')]: {
							width: 'auto',
							minWidth: '150px',
							left: '-70px',
						},
					}}
				>
					<Box
						zIndex={103}
						component={'img'}
						src={bottle}
						alt={''}
						position={'absolute'}
						height={'950px'}
						top={'-140px'}
						left={'-100px'}
						sx={{
							transform: 'rotate(-10deg)',
							userSelect: 'none',
							[breakpoints.down('xl')]: {
								height: 'auto',
								width: '560px',
								transform: 'rotate(0deg)',
							},
							[breakpoints.down('lg')]: {
								top: '-160px',
								left: '-120px',
								width: 'auto',
								height: '1000px',
							},
							[breakpoints.down('md')]: {
								height: 'calc(100% - 40px)',
								left: '-120px',
							},
							[breakpoints.down('sm')]: {
								width: '406px',
								height: 'auto',
								right: '0px',
								left: '-80px',
								top: '-200px',
							},
						}}
					/>
					<Box
						zIndex={102}
						component={'img'}
						src={bottleBackground}
						alt={''}
						position={'absolute'}
						height={'950px'}
						top={'-140px'}
						left={'-100px'}
						sx={{
							transform: 'rotate(-10deg)',
							userSelect: 'none',
							left: `${-100 + mousePosition.x / 40}px`,
							top: `${-140 + mousePosition.y / 40}px`,
							[breakpoints.down('xl')]: {
								height: 'auto',
								width: '560px',
								transform: 'rotate(0deg)',
							},
							[breakpoints.down('lg')]: {
								left: `${-120 + mousePosition.x / 40}px`,
								top: `${-160 + mousePosition.y / 40}px`,
								width: 'auto',
								height: '1000px',
							},
							[breakpoints.down('md')]: {
								left: `${-140 + mousePosition.x / 40}px`,
								height: 'calc(100% - 40px)',
							},
							[breakpoints.down('sm')]: {
								width: '406px',
								height: 'auto',
								right: '0',
								left: `${-80 + mousePosition.x / 40}px`,
								top: `${-200 + mousePosition.y / 40}px`,
							},
						}}
					/>
				</Box>
			</Grid>
		</Grid>
	);
};

export default GreenSection;
