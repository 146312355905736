import { Box, Grid, Typography, useTheme } from '@mui/material';
import Waves from '../ui/icons/Waves';
import highlight from '../ui/images/Highlight.png';
import graphicsRight from '../ui/images/YellowAsideRight.png';
import graphicsLeft from '../ui/images/YellowAsideLeft.png';
import graphicsMobile from '../ui/images/YellowAsideMobile.png';
import { useEffect, useState } from 'react';

const YellowSection = () => {
	const { breakpoints, typography } = useTheme();
	const [offsetY, setOffsetY] = useState(0);
	const handleScroll = () => setOffsetY(window.scrollY);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);

		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	return (
		<Grid
			zIndex={100}
			bgcolor={'#ffcf01'}
			position={'relative'}
			id={'filozofia'}
		>
			<Box
				component={'img'}
				src={graphicsLeft}
				alt={''}
				sx={{
					position: 'absolute',
					top: '50%',
					left: 0,
					zIndex: 101,
					transform: `translateY(-${offsetY * 0.5}px)`,
					[breakpoints.down('lg')]: {
						width: '187px',
					},
					[breakpoints.down('md')]: {
						width: '157px',
					},
					[breakpoints.down('sm')]: {
						display: 'none',
					},
				}}
			/>
			<Box
				component={'img'}
				src={graphicsRight}
				alt={''}
				sx={{
					position: 'absolute',
					top: '50%',
					right: 0,
					zIndex: 101,
					transform: `translateY(-${offsetY * 0.5}px)`,
					[breakpoints.down('lg')]: {
						top: '50%',
						width: '230px',
					},
					[breakpoints.down('md')]: {
						width: '193px',
					},
					[breakpoints.down('sm')]: {
						display: 'none',
					},
				}}
			/>
			<Box
				component={'img'}
				src={graphicsMobile}
				alt={''}
				sx={{
					display: 'none',
					position: 'absolute',
					top: '70%',
					right: 0,
					zIndex: 101,
					transform: `translateY(-${offsetY * 0.5}px)`,
					[breakpoints.down('sm')]: {
						display: 'block',
					},
				}}
			/>
			<Box
				width={'100%'}
				position={'absolute'}
				top={'-40px'}
				sx={{
					'& svg': {
						width: '100%',
						height: '100%',
					},
					[breakpoints.down('sm')]: {
						top: '-30px',
					},
				}}
			>
				<Waves color={'#ffcf01'} />
			</Box>
			<Grid
				display={'flex'}
				flexDirection={'column'}
				gap={'32px'}
				position={'relative'}
				zIndex={3}
			>
				<Typography
					mx={'80px'}
					color={'#003e1e'}
					textAlign={'center'}
					fontSize={'48px'}
					fontWeight={'800'}
					lineHeight={'60px'}
					letterSpacing={'0.72px'}
					fontFamily={typography.h1.fontFamily}
					sx={{
						[breakpoints.down('lg')]: {
							mx: '128px',
							fontSize: '30px',
							letterSpacing: '0.45px',
							lineHeight: 'normal',
						},
						[breakpoints.down('sm')]: {
							mx: '24px',
							fontSize: '18px',
							letterSpacing: '0.27px',
						},
					}}
				>
					5th Element to piwa, <Box component={'br'} />
					do których dodajemy coś wyjątkowego.
				</Typography>
				<Box
					display={'flex'}
					flexDirection={'column'}
					mx={'223px'}
					gap={'32px'}
					sx={{
						[breakpoints.down('lg')]: {
							mx: '128px',
							gap: '24px',
						},
						[breakpoints.down('sm')]: {
							mx: '24px',
						},
					}}
				>
					<Typography
						color={'#003e1e'}
						textAlign={'center'}
						fontSize={'20px'}
						fontWeight={'400'}
						lineHeight={'35px'}
						letterSpacing={'-0.3px'}
						sx={{
							[breakpoints.down('lg')]: {
								fontSize: '18px',
								letterSpacing: '-0.27px',
							},
							[breakpoints.down('sm')]: {
								fontSize: '14px',
								letterSpacing: '-0.21px',
								lineHeight: '24px',
							},
						}}
					>
						Oprócz czterech tradycyjnych składników piwowarskich{' '}
						<Box
							component={'span'}
							fontStyle={'italic'}
							fontWeight={'500'}
						>
							<Box component={'br'} />
							(wodę, słód browarniczy, chmiel i drożdże){' '}
							<Box
								component={'br'}
								display={'none'}
								sx={{
									[breakpoints.down('lg')]: {
										display: 'block',
									},
								}}
							/>
						</Box>
						wykorzystujemy dodatkowy, piąty składnik{' '}
						<Box
							component={'br'}
							display={'block'}
							sx={{
								[breakpoints.down('xl')]: {
									display: 'none',
								},
							}}
						/>
						o wyjątkowych{' '}
						<Box
							component={'br'}
							display={'none'}
							sx={{
								[breakpoints.down('lg')]: {
									display: 'block',
								},
							}}
						/>
						właściwościach i korzyściach.
					</Typography>
					<Typography
						color={'#003e1e'}
						textAlign={'center'}
						fontSize={'20px'}
						fontWeight={'400'}
						lineHeight={'35px'}
						letterSpacing={'-0.3px'}
						sx={{
							[breakpoints.down('lg')]: {
								fontSize: '18px',
								letterSpacing: '-0.27px',
							},
							[breakpoints.down('sm')]: {
								fontSize: '14px',
								letterSpacing: '-0.21px',
								lineHeight: '24px',
							},
						}}
					>
						<Typography
							component={'span'}
							fontSize={'28px'}
							fontWeight={'800'}
							lineHeight={'35px'}
							letterSpacing={'-0.42px'}
							fontFamily={typography.h1.fontFamily}
							sx={{
								[breakpoints.down('lg')]: {
									fontSize: '24px',
									letterSpacing: '-0.36px',
								},
								[breakpoints.down('sm')]: {
									fontSize: '18px',
									letterSpacing: '-0.27px',
									lineHeight: '24px',
								},
							}}
						>
							5th Element
						</Typography>{' '}
						to klasyczny smak i aromat rzemieślniczych{' '}
						<Box
							component={'br'}
							display={'none'}
							sx={{
								[breakpoints.down('lg')]: {
									display: 'block',
								},
							}}
						/>
						piw,{' '}
						<Box
							component={'br'}
							display={'block'}
							sx={{
								[breakpoints.down('lg')]: {
									display: 'none',
								},
							}}
						/>
						wzbogaconych o coś wyjątkowego.{' '}
						<Box
							component={'br'}
							display={'block'}
						/>
						Dlatego możemy powiedzieć „we want something better”{' '}
						<Box
							component={'br'}
							display={'none'}
							sx={{
								[breakpoints.down('lg')]: {
									display: 'block',
								},
							}}
						/>
						- chcemy czegoś więcej!
					</Typography>
				</Box>
			</Grid>
			<Box
				display={'flex'}
				justifyContent={'center'}
				my={'40px'}
			>
				<Box>
					<Typography
						zIndex={3}
						display={'inline-block'}
						color={'#fff'}
						textAlign={'center'}
						fontFamily={typography.h1.fontFamily}
						fontSize={'42px'}
						fontWeight={'800'}
						lineHeight={'80px'}
						letterSpacing={'0.63px'}
						position={'relative'}
						px={'30px'}
						mx={'244px'}
						sx={{
							backgroundImage: `url(${highlight})`,
							backgroundRepeat: 'round',
							backgroundPosition: '0 0',
							whiteSpace: 'nowrap',
							[breakpoints.down('lg')]: {
								fontSize: '30px',
								mx: '24px',
							},
							[breakpoints.down('sm')]: {
								mx: '4px',
								fontSize: '18px',
								letterSpacing: '0.27px',
								lineHeight: 'normal',
								p: '7px 20px',
							},
						}}
					>
						5th Element - piwo nowej generacji
					</Typography>
				</Box>
			</Box>
			<Box
				width={'100%'}
				position={'absolute'}
				bottom={'-40px'}
				zIndex={1}
				sx={{
					transform: 'scaleY(-1)',
					'& svg': {
						width: '100%',
						height: '100%',
					},
					[breakpoints.down('sm')]: {
						bottom: '-20px',
					},
				}}
			>
				<Waves color={'#ffcf01'} />
			</Box>
		</Grid>
	);
};

export default YellowSection;
