import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import background from '../ui/images/banner/bannerBackground.png';
import backgroundMobile from '../ui/images/banner/bannerBackgroundMobile.png';
import backgroundWater from '../ui/images/banner/bannerBackgroundWater.png';
import backgroundWaterMobile from '../ui/images/banner/bannerBackgroundWaterMobile.png';
import highlight from '../ui/images/banner/bannerHighlight.png';
import whiteHighlight from '../ui/images/banner/bannerWhiteHighlight.png';
import greenBottle from '../ui/images/greenBottle.png';
import blueBottle from '../ui/images/blueBottle.png';
import greenBottleBackground from '../ui/images/greenBottleBackground.png';
import blueBottleBackground from '../ui/images/blueBottleBackground.png';

const Banner = () => {
	const { breakpoints, typography } = useTheme();
	const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

	const handleMouseMove = (event: MouseEvent) => {
		setMousePosition({ x: event.clientX, y: event.clientY });
	};

	useEffect(() => {
		document.addEventListener('mousemove', handleMouseMove);
		return () => {
			document.removeEventListener('mousemove', handleMouseMove);
		};
	}, []);

	return (
		<Grid
			component={'section'}
			position={'relative'}
			sx={{
				background: `url(${background})`,
				backgroundSize: '100% 100%',
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'center',
				height: '105vh',
				width: '100%',
				[breakpoints.down('sm')]: {
					background: `url(${backgroundMobile})`,
					backgroundSize: '100% 100%',
					backgroundPosition: 'center',
					height: '850px',
				},
			}}
		>
			<Box
				component={'img'}
				src={backgroundWater}
				alt={''}
				position={'absolute'}
				sx={{
					inset: '0',
					m: 'auto',
					width: '60%',
					right: `${-mousePosition.x / 40}px`,
					top: `${mousePosition.y / 40}px`,
					[breakpoints.down('md')]: {
						width: '100%',
					},
					[breakpoints.down('sm')]: {
						display: 'none',
					},
				}}
			/>
			<Box
				display={'none'}
				component={'img'}
				src={backgroundWaterMobile}
				alt={''}
				position={'absolute'}
				sx={{
					inset: '0',
					m: 'auto',
					width: '100%',
					right: `${-mousePosition.x / 40}px`,
					top: `${mousePosition.y / 40}px`,
					[breakpoints.down('sm')]: {
						display: 'block',
					},
				}}
			/>
			<Box
				position={'absolute'}
				sx={{
					inset: '0',
					left: '0',
					top: '0',
					m: 'auto',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Box
					zIndex={5}
					component={'img'}
					src={greenBottle}
					alt={''}
					position={'relative'}
					right={'-125px'}
					top={'0px'}
					height={'700px'}
					sx={{
						[breakpoints.down('md')]: {
							right: '-100px',
							height: '550px',
						},
						[breakpoints.down('sm')]: {
							position: 'absolute',
							right: 'auto',
							height: '465px',
							top: '40px',
							left: '-20px',
						},
					}}
				/>

				<Box
					zIndex={4}
					component={'img'}
					src={blueBottle}
					alt={''}
					position={'relative'}
					left={'-125px'}
					top={'-40px'}
					height={'700px'}
					sx={{
						[breakpoints.down('md')]: {
							left: '-100px',
							top: '-20px',
							height: '550px',
						},
						[breakpoints.down('sm')]: {
							position: 'absolute',
							transform: 'rotate(-10deg)',
							top: '260px',
							left: 'auto',
							right: '-20px',
							height: '465px',
						},
					}}
				/>
				<Box
					zIndex={3}
					component={'img'}
					src={greenBottleBackground}
					alt={''}
					position={'absolute'}
					top={'-80px'}
					left={'-155px'}
					right={'0'}
					m={'0 auto'}
					sx={{
						userSelect: 'none',
						left: `${-155 + mousePosition.x / 40}px`,
						top: `${-80 + mousePosition.y / 40}px`,
						[breakpoints.down('lg')]: {
							left: `${-155 + mousePosition.x / 40}px`,
							top: `${-80 + mousePosition.y / 40}px`,
						},
						[breakpoints.down('md')]: {
							left: `${-200 + mousePosition.x / 40}px`,
							top: `${mousePosition.y / 40}px`,
							height: '550px',
						},
						[breakpoints.down('sm')]: {
							right: 'auto',
							left: `${-20 + mousePosition.x / 40}px`,
							top: `${50 + mousePosition.y / 40}px`,
							height: '465px',
						},
					}}
				/>
				<Box
					zIndex={3}
					component={'img'}
					src={blueBottleBackground}
					alt={''}
					position={'absolute'}
					top={'-40px'}
					right={'-155px'}
					left={'0'}
					m={'0 auto'}
					sx={{
						userSelect: 'none',
						right: `${-155 - mousePosition.x / 40}px`,
						top: `${-40 + mousePosition.y / 40}px`,
						[breakpoints.down('lg')]: {
							right: `${-155 - mousePosition.x / 40}px`,
							top: `${-40 + mousePosition.y / 40}px`,
						},
						[breakpoints.down('md')]: {
							right: `${-200 - mousePosition.x / 40}px`,
							top: `${-20 + mousePosition.y / 40}px`,
							height: '550px',
						},
						[breakpoints.down('sm')]: {
							left: 'auto',
							right: `${-20 - mousePosition.x / 40}px`,
							top: `${270 + mousePosition.y / 40}px`,
							height: '465px',
						},
					}}
				/>
			</Box>
			<Box
				zIndex={6}
				position={'relative'}
				height={'100%'}
			>
				<Box
					component={'aside'}
					sx={{
						writingMode: 'vertical-rl',
						textOrientation: 'sideways',
						pt: '30vh',
						// pb: '40px',
						// pb: '110px',
						pb: '6vh',
						ml: '80px',
						position: 'relative',
						zIndex: 1,
						background: `url(${highlight})`,
						// backgroundSize: 'contain',
						backgroundSize: '110% 100%',
						backgroundRepeat: 'no-repeat',
						backgroundPosition: 'center',
						display: 'flex',
						[breakpoints.down('lg')]: {
							ml: '40px',
						},
						[breakpoints.down('sm')]: {
							pt: '18vh',
							ml: '24px',
						},
					}}
				>
					<Typography
						fontFamily={typography.h1.fontFamily}
						fontSize={'42px'}
						fontWeight={'800'}
						textAlign={'center'}
						color={'#005329'}
						lineHeight={'80px'}
						letterSpacing={'0.72px'}
						height={'100%'}
						sx={{
							transform: 'rotate(180deg)',
							[breakpoints.down('xl')]: {
								fontSize: '36px',
							},
							[breakpoints.down('lg')]: {
								fontSize: '32px',
							},
							[breakpoints.down('md')]: {
								fontSize: '28px',
								letterSpacing: '0.48px',
							},
							[breakpoints.down('sm')]: {
								fontSize: '22px',
								letterSpacing: '0.36px',
								lineHeight: '50px',
							},
						}}
					>
						NOWOŚĆ
					</Typography>
				</Box>
				<Box
					sx={{
						position: 'absolute',
						bottom: '60px',
						left: '0',
						right: '0',
						zIndex: 1,
						display: 'inline-flex',
						justifyContent: 'center',
						[breakpoints.down('md')]: { bottom: '75px' },
						[breakpoints.down('sm')]: { bottom: '60px' },
					}}
				>
					<Typography
						fontFamily={typography.h1.fontFamily}
						fontSize={'48px'}
						fontWeight={'800'}
						textAlign={'center'}
						color={'#005329'}
						lineHeight={'80px'}
						letterSpacing={'0.72px'}
						whiteSpace={'nowrap'}
						sx={{
							px: '132px',
							background: `url(${whiteHighlight})`,
							backgroundSize: '100%',
							backgroundRepeat: 'no-repeat',
							backgroundPosition: 'center',
							[breakpoints.down('lg')]: {
								px: '80px',
								fontSize: '32px',
								letterSpacing: '0.48px',
							},
							[breakpoints.down('sm')]: {
								px: '56px',
								fontSize: '18px',
								letterSpacing: '0.27px',
							},
						}}
					>
						we want something better
					</Typography>
				</Box>
			</Box>
		</Grid>
	);
};

export default Banner;
