import { Box, Grid, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import theme from '../styles/theme';
import stain from '../ui/images/Stain.png';
import bigLogo from '../ui/images/BigLogo.png';
import logoThin from '../ui/images/LogoThin.png';

function Header() {
	const { breakpoints } = useTheme();
	const [isFixed, setIsFixed] = useState(false);
	const headerHeight = 140;
	const stickyHeaderHeight = 60;

	useEffect(() => {
		function handleScroll() {
			const scrollTop = window.scrollY;

			if (scrollTop > headerHeight && !isFixed) {
				setIsFixed(true);
			} else if (scrollTop <= headerHeight && isFixed) {
				setIsFixed(false);
			}
		}

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [isFixed]);

	return (
		<>
			<Grid
				component={'header'}
				width={'100%'}
				bgcolor={'transparent'}
				height={headerHeight}
				zIndex={theme.zIndex.appBar}
				position={'absolute'}
				display={'flex'}
				justifyContent={'flex-end'}
				pr={'80px'}
				sx={{
					opacity: isFixed ? '0' : '1',
					transition: 'opacity .3s ease-in-out',
					[breakpoints.down('sm')]: {
						pr: '40px',
					},
				}}
			>
				<Box
					component={'img'}
					src={stain}
					alt={''}
					alignSelf={'flex-start'}
					position={'absolute'}
					left={'0'}
					sx={{
						[breakpoints.down('lg')]: {
							transform: 'scale(80%)',
							transformOrigin: 'top left',
						},
						[breakpoints.down('sm')]: {
							transform: 'scale(40%)',
						},
					}}
				/>
				<Box
					component={'img'}
					src={bigLogo}
					alt={''}
					alignSelf={'flex-start'}
					sx={{
						[breakpoints.down('lg')]: {
							transform: 'scale(80%)',
							transformOrigin: 'top right',
						},
						[breakpoints.down('sm')]: {
							transform: 'scale(50%)',
						},
					}}
				/>
			</Grid>
			<Grid
				position={'fixed'}
				display={'flex'}
				justifyContent={'flex-end'}
				pr={'80px'}
				width={'100%'}
				height={stickyHeaderHeight}
				sx={{
					zIndex: theme.zIndex.appBar,
					top: isFixed ? '0px' : '-200px',
					transition: 'top .5s ease-in-out',
					bgcolor: 'transparent',
					width: '100vw',
					[breakpoints.down('sm')]: {
						pr: '40px',
					},
				}}
			>
				<Box
					position={'absolute'}
					left={'0'}
					display={'block'}
					component={'img'}
					src={stain}
					alt={''}
					height={'228px'}
					sx={{
						[breakpoints.down('lg')]: {
							transform: 'scale(80%)',
							transformOrigin: 'top left',
						},
						[breakpoints.down('sm')]: {
							transform: 'scale(50%)',
						},
					}}
				/>
				<Box
					bgcolor={'#fff'}
					p={'40px 20px 40px'}
					display={'flex'}
					justifyContent={'center'}
					alignItems={'center'}
					sx={{
						[breakpoints.down('lg')]: {
							transform: 'scale(80%)',
							transformOrigin: 'top right',
						},
						[breakpoints.down('sm')]: {
							transform: 'scale(60%)',
							transformOrigin: 'top right',
						},
					}}
				>
					<Box
						component={'img'}
						src={logoThin}
						alt={''}
						height={'40px'}
					/>
				</Box>
			</Grid>
		</>
	);
}

export default Header;
