import React from 'react';

const LinkedInIcon = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
		>
			<g clipPath='url(#clip0_44_46)'>
				<path
					d='M9.07907 12.0279V8.33488H13.0791V12.0279H9.07907ZM9.07907 29.1907V14.0837H13.0791V29.1907H9.07907ZM30.9209 29.1907H26.9209V21.4791C26.9209 19.8512 26.8372 18.8 26.6605 18.3163C26.493 17.8326 26.214 17.4605 25.8326 17.2C25.4512 16.9302 24.986 16.8 24.4465 16.8C23.7488 16.8 23.1349 16.986 22.586 17.3674C22.0372 17.7395 21.6558 18.2419 21.4512 18.8651C21.2465 19.4884 21.1442 20.6512 21.1442 22.3349V29.1814H17.1442V14.0837H20.8558V16.2977C22.1767 14.5953 23.8326 13.7395 25.8326 13.7395C26.7163 13.7395 27.5256 13.907 28.2512 14.2233C28.9767 14.5395 29.5349 14.9488 29.907 15.4419C30.2791 15.9256 30.5395 16.493 30.6884 17.1163C30.8372 17.7302 30.9116 18.6326 30.9116 19.8047V29.1907H30.9209ZM34.1395 5.86047C30.3628 2.08372 25.3395 0 20 0C14.6605 0 9.63721 2.08372 5.86047 5.86047C2.08372 9.63721 0 14.6605 0 20C0 25.3395 2.08372 30.3628 5.86047 34.1395C9.63721 37.9163 14.6605 40 20 40C25.3395 40 30.3628 37.9163 34.1395 34.1395C37.9163 30.3628 40 25.3395 40 20C40 14.6605 37.9163 9.63721 34.1395 5.86047ZM33.8977 33.8977C30.186 37.6093 25.2465 39.6558 20 39.6558C14.7535 39.6558 9.81395 37.6093 6.10233 33.8977C2.3907 30.186 0.344186 25.2465 0.344186 20C0.344186 14.7535 2.3907 9.81395 6.10233 6.10233C9.81395 2.3907 14.7535 0.344186 20 0.344186C25.2465 0.344186 30.186 2.3907 33.8977 6.10233C37.6093 9.81395 39.6558 14.7535 39.6558 20C39.6558 25.2465 37.6093 30.186 33.8977 33.8977Z'
					fill='#1D1D1B'
				/>
			</g>
			<defs>
				<clipPath id='clip0_44_46'>
					<rect
						width='40'
						height='40'
						fill='white'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default LinkedInIcon;
