import { Box, Divider, Grid, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import highlight from '../ui/images/blueHighlight.png';
import bottleBackground from '../ui/images/blueBottleBackground.png';
import bottle from '../ui/images/blueBottle.png';
import salt from '../ui/images/salt.png';
import oat from '../ui/images/oat.png';

const BlueSection = () => {
	const { breakpoints, typography } = useTheme();
	const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

	const handleMouseMove = (event: MouseEvent) => {
		setMousePosition({ x: event.clientX, y: event.clientY });
	};

	useEffect(() => {
		document.addEventListener('mousemove', handleMouseMove);
		return () => {
			document.removeEventListener('mousemove', handleMouseMove);
		};
	}, []);

	return (
		<Grid
			id={'nasze_piwa'}
			component={'section'}
			position={'relative'}
			sx={{
				background: 'linear-gradient(90deg, #007DB7 0%, #00A3DE 50%, #007CBB 100%)',
			}}
		>
			<Grid
				component={'section'}
				display={'flex'}
				justifyContent={'space-between'}
				gap={'54px'}
				pr={'100px'}
				pl={'128px'}
				mt={'100px'}
				mb={'80px'}
				width={'100%'}
				sx={{
					[breakpoints.down('xl')]: {
						pl: '80px',
						gap: '12px',
					},
					[breakpoints.down('lg')]: {
						pl: '40px',
					},
					[breakpoints.down('md')]: {
						mx: '0',
					},
					[breakpoints.down('sm')]: {
						px: '25px',
						flexDirection: 'column-reverse',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
					},
				}}
			>
				<Grid
					display={'flex'}
					flexDirection={'column'}
					alignItems={'flex-start'}
					width={'695px'}
					gap={'24px'}
					sx={{
						[breakpoints.down('lg')]: {
							width: '425px',
						},
						[breakpoints.down('sm')]: {
							width: '100%',
							mt: '450px',
						},
					}}
				>
					<Typography
						color={'#fff'}
						fontSize={'30px'}
						fontWeight={'700'}
						lineHeight={'36px'}
						letterSpacing={'-0.45px'}
						textAlign={'center'}
						bgcolor={'#163F77'}
						p={'16px 30px'}
						alignSelf={'center'}
						zIndex={1}
						sx={{
							whiteSpace: 'nowrap',
							[breakpoints.down('lg')]: {
								fontSize: '19px',
								lineHeight: '36px',
								letterSpacing: '-0.3px',
								px: '14px',
								width: '425px',
							},
							[breakpoints.down('sm')]: {
								width: 'auto',
								fontSize: '14px',
								lineHeight: '36px',
								letterSpacing: '-0.21px',
							},
						}}
					>
						American Pale Ale z owsem i solą morską
					</Typography>
					<Box
						display={'flex'}
						gap={'24px'}
						alignItems={'center'}
						sx={{
							[breakpoints.down('lg')]: {
								flexDirection: 'column',
							},
							[breakpoints.down('md')]: {
								width: '100%',
							},
						}}
					>
						<Typography
							color={'#00142f'}
							fontSize={'18px'}
							fontWeight={'400'}
							lineHeight={'30px'}
							letterSpacing={'-0.27px'}
							textAlign={'center'}
							sx={{
								[breakpoints.down('sm')]: {
									fontSize: '16px',
								},
							}}
						>
							Lekkie, średnia goryczka,{' '}
							<Box
								component={'br'}
								sx={{
									[breakpoints.down('lg')]: {
										display: 'none',
									},
								}}
							/>
							orzeźwiające,{' '}
							<Box
								component={'br'}
								sx={{
									display: 'none',
									[breakpoints.down('lg')]: {
										display: 'block',
									},
								}}
							/>
							bezalkoholowe. Wzbogacone owsem{' '}
							<Box
								component={'br'}
								sx={{
									display: 'none',
									[breakpoints.down('lg')]: {
										display: 'block',
									},
								}}
							/>
							i solą morską.
						</Typography>
						<Box
							display={'flex'}
							justifyContent={'space-between'}
							alignItems={'center'}
							sx={{
								[breakpoints.down('lg')]: {
									width: '425px',
									justifyContent: 'center',
									'& div': {
										width: '33%',
										textAlign: 'center',
									},
								},
								[breakpoints.down('md')]: {
									width: '100%',
								},
							}}
						>
							<Box
								display={'flex'}
								flexDirection={'column'}
								alignItems={'center'}
								gap={'16px'}
								borderLeft={'1px dotted #002251'}
								px={'16px'}
								my={'16px'}
							>
								<Typography
									color={'#00142f'}
									fontSize={'32px'}
									fontWeight={'600'}
									lineHeight={'30px'}
									letterSpacing={'-0.48px'}
									textAlign={'center'}
									mt={'8px'}
									sx={{
										[breakpoints.down('sm')]: {
											fontSize: '24px',
										},
									}}
								>
									7,0%
								</Typography>
								<Typography
									color={'#00142f'}
									fontSize={'14px'}
									fontWeight={'400'}
									lineHeight={'30px'}
									letterSpacing={'-0.21px'}
									textAlign={'center'}
									sx={{
										[breakpoints.down('sm')]: {
											fontSize: '12px',
										},
									}}
								>
									Ekstrakt
								</Typography>
							</Box>
							<Box
								display={'flex'}
								flexDirection={'column'}
								gap={'16px'}
								borderLeft={'1px dotted #002251'}
								px={'8px'}
								my={'16px'}
							>
								<Typography
									color={'#00142f'}
									fontSize={'32px'}
									fontWeight={'600'}
									lineHeight={'30px'}
									letterSpacing={'-0.48px'}
									textAlign={'center'}
									mt={'8px'}
									sx={{
										[breakpoints.down('sm')]: {
											fontSize: '24px',
										},
									}}
								>
									{'<'}0,5%
								</Typography>
								<Typography
									color={'#00142f'}
									fontSize={'14px'}
									fontWeight={'400'}
									lineHeight={'30px'}
									letterSpacing={'-0.21px'}
									textAlign={'center'}
									sx={{
										[breakpoints.down('sm')]: {
											fontSize: '12px',
										},
									}}
								>
									Alkohol
								</Typography>
							</Box>
							<Box
								display={'flex'}
								flexDirection={'column'}
								gap={'16px'}
								borderLeft={'1px dotted #002251'}
								borderRight={'1px dotted #002251'}
								px={'16px'}
								my={'16px'}
							>
								<Typography
									color={'#00142f'}
									fontSize={'32px'}
									fontWeight={'600'}
									lineHeight={'30px'}
									letterSpacing={'-0.48px'}
									mt={'8px'}
									sx={{
										[breakpoints.down('sm')]: {
											fontSize: '24px',
										},
									}}
								>
									5/10
								</Typography>
								<Typography
									color={'#00142f'}
									fontSize={'14px'}
									fontWeight={'400'}
									lineHeight={'30px'}
									letterSpacing={'-0.21px'}
									sx={{
										[breakpoints.down('sm')]: {
											fontSize: '12px',
										},
									}}
								>
									Goryczka
								</Typography>
							</Box>
						</Box>
					</Box>
					<Grid
						display={'flex'}
						flexDirection={'column'}
						gap={'24px'}
						border={'1px solid #163f77'}
						width={'695px'}
						position={'relative'}
						sx={{
							borderImage: 'linear-gradient(#163f77 14%, transparent 0 85%,#163f77 0) 2',
							[breakpoints.down('lg')]: {
								borderImage: 'linear-gradient(#163f77 24%, transparent 0 90%,#163f77 0) 2',
								width: '425px',
							},
							[breakpoints.down('md')]: {
								width: '100%',
							},
						}}
					>
						<Box
							component={'aside'}
							height={'100px'}
							width={'1px'}
							position={'absolute'}
							right={'-1px'}
							borderRight={'1px solid #163f77'}
							sx={{
								[breakpoints.down('lg')]: {
									height: '125px',
								},
								[breakpoints.down('md')]: {
									height: '45%',
								},
							}}
						/>
						<Box
							component={'aside'}
							height={'125px'}
							width={'1px'}
							position={'absolute'}
							left={'-1px'}
							bottom={'0'}
							borderLeft={'1px solid #163f77'}
							sx={{
								[breakpoints.down('lg')]: {
									height: '100px',
								},
								[breakpoints.down('md')]: {
									height: '25%',
								},
							}}
						/>
						<Box
							sx={{
								position: 'absolute',
								inset: '0',
								m: '0 auto',
								width: '328px',
								top: '-24px',
								[breakpoints.down('sm')]: {
									width: '300px',
								},
							}}
						>
							<Typography
								bgcolor={'#163f77'}
								display={'inline-flex'}
								p={'8px 34px'}
								justifyContent={'center'}
								alignItems={'center'}
								gap={'10px'}
								sx={{
									color: '#FFF',
									fontFamily: typography.h1.fontFamily,
									fontSize: '30px',
									fontWeight: '800',
									lineHeight: '30px',
									letterSpacing: '-0.45px',
									[breakpoints.down('lg')]: {
										fontSize: '24px',
										width: '100%',
									},
									[breakpoints.down('sm')]: {
										px: '16px',
									},
								}}
							>
								Wyjątkowe składniki:
							</Typography>
						</Box>
						<Box
							display={'flex'}
							justifyContent={'space-between'}
							alignItems={'center'}
							gap={'8px'}
							m={'40px 40px 0 40px'}
							position={'relative'}
							sx={{
								[breakpoints.down('lg')]: {
									mx: '17px',
								},
							}}
						>
							<Box
								component={'img'}
								src={salt}
								alt={''}
								position={'absolute'}
								left={'-120px'}
								sx={{
									[breakpoints.down('lg')]: {
										width: '83px',
										top: '90px',
										left: '-40px',
									},
								}}
							/>
							<Typography
								textAlign={'center'}
								color={'#00142f'}
								fontSize={'50px'}
								fontWeight={'800'}
								lineHeight={'30px'}
								letterSpacing={'-0.75px'}
								fontFamily={typography.h1.fontFamily}
								sx={{
									[breakpoints.down('lg')]: {
										fontSize: '32px',
										letterSpacing: '-0.48px',
										whiteSpace: 'nowrap',
									},
									[breakpoints.down('sm')]: {
										fontSize: '20px',
										letterSpacing: '-0.3px',
									},
								}}
							>
								Sól morska
							</Typography>
							<Typography
								color={'#00142f'}
								fontSize={'18px'}
								fontWeight={'400'}
								lineHeight={'21px'}
								letterSpacing={'-0.27px'}
								sx={{
									[breakpoints.down('sm')]: {
										fontSize: '14px',
									},
								}}
							>
								Zawiera{' '}
								<Typography
									component={'span'}
									fontWeight={'600'}
									sx={{
										[breakpoints.down('sm')]: {
											fontSize: '14px',
										},
									}}
								>
									cenne elektrolity
								</Typography>
								. Uzupełnianie{' '}
								<Box
									component={'br'}
									sx={{
										[breakpoints.down('lg')]: {
											display: 'none',
										},
									}}
								/>
								elektrolitów i minerałów ma ogromne{' '}
								<Box
									component={'br'}
									sx={{
										[breakpoints.down('lg')]: {
											display: 'none',
										},
									}}
								/>
								znaczenie przy wysiłku fizycznym{' '}
								<Box
									component={'br'}
									sx={{
										[breakpoints.down('lg')]: {
											display: 'none',
										},
									}}
								/>
								i umysłowym.
							</Typography>
						</Box>
						<Divider
							sx={{
								mx: '100px',
								alignItems: 'center',
								borderColor: '#163f77',
								[breakpoints.down('lg')]: {
									mx: '17px',
								},
							}}
						/>
						<Box
							display={'flex'}
							justifyContent={'space-between'}
							alignItems={'center'}
							m={'0 40px 40px 40px'}
							position={'relative'}
							sx={{
								[breakpoints.down('lg')]: {
									mx: '17px',
									mb: '17px',
									gap: '8px',
								},
							}}
						>
							<Typography
								color={'#00142f'}
								fontSize={'18px'}
								fontWeight={'400'}
								letterSpacing={'-0.27px'}
								textAlign={'right'}
								sx={{
									[breakpoints.down('lg')]: {
										lineHeight: '21px',
									},
									[breakpoints.down('sm')]: {
										fontSize: '14px',
									},
								}}
							>
								Jest pełen wartości odżywczych. To{' '}
								<Typography
									component={'span'}
									color={'#00142f'}
									fontSize={'18px'}
									fontWeight={'600'}
									letterSpacing={'-0.27px'}
									sx={{
										[breakpoints.down('sm')]: {
											fontSize: '14px',
										},
									}}
								>
									źródło błonnika,{' '}
									<Box
										component={'br'}
										sx={{
											[breakpoints.down('lg')]: {
												display: 'none',
											},
										}}
									/>
									minerałów{' '}
									<Box
										component={'br'}
										sx={{
											display: 'none',
											[breakpoints.down('lg')]: {
												display: 'block',
											},
											[breakpoints.down('md')]: {
												display: 'none',
											},
										}}
									/>
									i witamin.
								</Typography>
							</Typography>
							<Typography
								color={'#00142f'}
								fontFamily={typography.h1.fontFamily}
								fontSize={'50px'}
								fontWeight={'800'}
								lineHeight={'30px'}
								letterSpacing={'-0.75px'}
								sx={{
									[breakpoints.down('lg')]: {
										fontSize: '32px',
										letterSpacing: '-0.48px',
									},
									[breakpoints.down('sm')]: {
										fontSize: '20px',
										letterSpacing: '-0.3px',
									},
								}}
							>
								Owies
							</Typography>
							<Box
								component={'img'}
								src={oat}
								alt={''}
								position={'absolute'}
								right={'-110px'}
								top={'-65px'}
								sx={{
									[breakpoints.down('lg')]: {
										transform: 'rotate(-10deg)',
										width: '65px',
										top: '-40px',
										right: '-60px',
									},
									[breakpoints.down('sm')]: {
										transform: 'rotate(-30deg)',
										width: '65px',
										top: '-30px',
										right: '-50px',
									},
								}}
							/>
						</Box>
					</Grid>
					<Box
						display={'flex'}
						flexDirection={'column'}
						gap={'24px'}
					>
						<Typography
							color={'#00142f'}
							fontSize={'18px'}
							fontWeight={'400'}
							lineHeight={'30px'}
							letterSpacing={'-0.27px'}
							textAlign={'center'}
							sx={{
								[breakpoints.down('lg')]: {
									fontSize: '16px',
									letterSpacing: '-0.24px',
								},
								[breakpoints.down('sm')]: {
									fontSize: '14px',
									lineHeight: '21px',
									letterSpacing: '-0.21px',
								},
							}}
						>
							<Typography
								component={'span'}
								fontWeight={'600'}
								sx={{
									[breakpoints.down('sm')]: {
										fontSize: '14px',
										lineHeight: '21px',
										letterSpacing: '-0.21px',
									},
								}}
							>
								Surowce:
							</Typography>{' '}
							woda, słód jęczmienny (Pale Ale, Monachisjki), słód pszeniczny, płatki owsiane, chmiel (Cascade, Chinook,
							Iunga), drożdże, sól morska.
						</Typography>
						<Typography
							color={'#00142f'}
							fontSize={'18px'}
							fontWeight={'400'}
							lineHeight={'30px'}
							letterSpacing={'-0.27px'}
							textAlign={'center'}
							sx={{
								[breakpoints.down('lg')]: {
									fontSize: '16px',
									letterSpacing: '-0.24px',
								},
								[breakpoints.down('sm')]: {
									fontSize: '14px',
									lineHeight: '21px',
									letterSpacing: '-0.21px',
								},
							}}
						>
							<Typography
								component={'span'}
								fontWeight={'600'}
								sx={{
									[breakpoints.down('sm')]: {
										fontSize: '14px',
										lineHeight: '21px',
										letterSpacing: '-0.21px',
									},
								}}
							>
								Wartości odżywcze w 100ml produktu:
							</Typography>{' '}
							wartość energetyczna: 182 kJ/42,8 kcal; tłuszcz {'<'}0,1g, w tym kwasy tłuszczowe nasycone: {'<'}0,1g;
							węglowodany: 10,3g, w tym cukry: 5,6g; białko 0,39g; sól {'<'}0,5
						</Typography>
					</Box>
				</Grid>
				<Box
					position={'relative'}
					component={'aside'}
					sx={{
						width: '328px',
						m: '0',
						[breakpoints.down('md')]: {
							width: 'auto',
							minWidth: '80px',
							right: '-70px',
						},
						[breakpoints.down('sm')]: {
							width: 'auto',
							minWidth: '150px',
							right: '-70px',
						},
					}}
				>
					<Box
						display={'flex'}
						zIndex={0}
						sx={{
							background: `url(${highlight})`,
							backgroundRepeat: 'no-repeat',
							backgroundSize: '100%',
							p: '0 80px 0 40px',
							mr: '90px',
							width: '120%',
							position: 'absolute',
							left: '-150px',
							[breakpoints.down('xl')]: {
								left: '-80px',
								width: '100%',
								mr: '50px',
								pl: '20px',
							},
							[breakpoints.down('lg')]: {
								left: '-50px',
								width: '280px',
								backgroundSize: 'auto',
								py: '10px',
							},
							[breakpoints.down('md')]: {
								left: '-160px',
								width: '200px',
								backgroundSize: 'auto',
								py: '10px',
							},
							[breakpoints.down('sm')]: {
								top: '-30px',
								left: '-160px',
								width: '200px',
								backgroundSize: 'auto',
								py: '10px',
							},
						}}
					>
						<Typography
							textTransform={'uppercase'}
							color={'#002251'}
							textAlign={'center'}
							fontFamily={typography.h1.fontFamily}
							fontSize={'42px'}
							fontWeight={'800'}
							lineHeight={'80px'}
							letterSpacing={'0.63px'}
							sx={{
								[breakpoints.down('lg')]: {
									fontSize: '30px',
									letterSpacing: '0.36px',
									lineHeight: 'normal',
								},
								[breakpoints.down('md')]: {
									fontSize: '24px',
									letterSpacing: '0.36px',
									lineHeight: 'normal',
								},
							}}
						>
							hydrate
						</Typography>
					</Box>
					<Box
						zIndex={103}
						component={'img'}
						src={bottle}
						alt={''}
						position={'absolute'}
						height={'950px'}
						top={'-140px'}
						right={'-100px'}
						sx={{
							transform: 'rotate(0deg)',
							userSelect: 'none',
							[breakpoints.down('xl')]: {
								height: 'auto',
								width: '560px',
								transform: 'rotate(-10deg)',
							},
							[breakpoints.down('lg')]: {
								top: '-160px',
								right: '-120px',
								width: 'auto',
								height: '1000px',
							},
							[breakpoints.down('md')]: {
								height: 'calc(100% - 140px)',
								right: '-120px',
							},
							[breakpoints.down('sm')]: {
								width: '406px',
								height: 'auto',
								left: '-200px',
								right: 'auto',
								top: '-140px',
							},
						}}
					/>
					<Box
						zIndex={102}
						component={'img'}
						src={bottleBackground}
						alt={''}
						position={'absolute'}
						height={'950px'}
						top={'-140px'}
						right={'-100px'}
						sx={{
							transform: 'rotate(0deg)',
							userSelect: 'none',
							right: `${-100 - mousePosition.x / 40}px`,
							top: `${-140 + mousePosition.y / 40}px`,
							[breakpoints.down('xl')]: {
								height: 'auto',
								width: '560px',
								transform: 'rotate(-10deg)',
							},
							[breakpoints.down('lg')]: {
								right: `${-120 - mousePosition.x / 40}px`,
								top: `${-160 + mousePosition.y / 40}px`,
								width: 'auto',
								height: '1000px',
							},
							[breakpoints.down('md')]: {
								right: `${-140 - mousePosition.x / 40}px`,
								height: 'calc(100% - 140px)',
							},
							[breakpoints.down('sm')]: {
								width: '406px',
								height: 'auto',
								right: 'auto',
								left: `${-200 + mousePosition.x / 40}px`,
								top: `${-140 + mousePosition.y / 40}px`,
							},
						}}
					/>
				</Box>
			</Grid>
		</Grid>
	);
};

export default BlueSection;
