import { Box, Grid } from '@mui/material';
import GreenSection from './components/GreenSection';
import YellowSection from './components/YellowSection';
import BlueSection from './components/BlueSection';
import { useEffect, useState } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Banner from './components/Banner';

function App() {
	const [isFixed, setIsFixed] = useState(false);

	useEffect(() => {
		function handleScroll() {
			const scrollTop = window.scrollY;
			const headerHeight = 100;

			if (scrollTop > headerHeight && !isFixed) {
				setIsFixed(true);
			} else if (scrollTop <= headerHeight && isFixed) {
				setIsFixed(false);
			}
		}

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [isFixed]);

	return (
		<Grid
			component={'section'}
			container
			flexDirection={'column'}
			width={'100%'}
			sx={{
				overflow: 'hidden',
			}}
		>
			<Header />
			<Banner />
			<YellowSection />
			<BlueSection />
			<GreenSection />
			<Footer />
		</Grid>
	);
}

export default App;
