import React from 'react';

const FacebookIcon = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
		>
			<g clipPath='url(#clip0_44_44)'>
				<path
					d='M13.3752 14.1571H16.2537V12.6846C16.2537 11.0342 16.4288 9.80263 16.7796 8.99324C17.1304 8.18179 17.7764 7.52 18.719 7.00717C19.6595 6.4971 20.8499 6.24103 22.2895 6.24103C23.7291 6.24103 25.212 6.46277 26.6255 6.90694L25.9246 10.5235C25.0994 10.3271 24.3051 10.2269 23.5438 10.2269C22.7824 10.2269 22.2552 10.4047 21.9291 10.7541C21.6009 11.1049 21.4389 11.7777 21.4389 12.7745V14.1565H25.3156V18.2377H21.4389V33.7576H16.253V18.2377H13.3745V14.1565L13.3752 14.1571ZM34.142 5.85796C30.3642 2.08012 25.3417 0 20 0C14.6583 0 9.63512 2.08012 5.85796 5.85796C2.0808 9.63581 0 14.6576 0 20C0 25.3424 2.08012 30.3649 5.85796 34.142C9.63581 37.9192 14.6576 40 20 40C25.3424 40 30.3649 37.9199 34.142 34.142C37.9192 30.3642 40 25.3424 40 20C40 14.6576 37.9199 9.63512 34.142 5.85796ZM33.899 33.899C30.1864 37.6116 25.2504 39.6561 20 39.6561C14.7496 39.6561 9.81361 37.6116 6.10099 33.899C2.38767 30.1864 0.343253 25.2504 0.343253 20C0.343253 14.7496 2.38767 9.81361 6.1003 6.10099C9.81293 2.38836 14.7496 0.343253 20 0.343253C25.2504 0.343253 30.1864 2.38767 33.899 6.1003C37.6116 9.81293 39.6561 14.7489 39.6561 19.9993C39.6561 25.2497 37.6116 30.1857 33.899 33.8983V33.899Z'
					fill='#1D1D1B'
				/>
			</g>
			<defs>
				<clipPath id='clip0_44_44'>
					<rect
						width='40'
						height='40'
						fill='white'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default FacebookIcon;
