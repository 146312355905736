import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './styles/theme';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<React.StrictMode>
		<link
			href='https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap'
			rel='stylesheet'
		/>
		<link
			href='https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@800&display=swap'
			rel='stylesheet'
		/>
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<App />
		</ThemeProvider>
	</React.StrictMode>,
);
