import { Box, Divider, Grid, Link, Typography, useTheme } from '@mui/material';
import FacebookIcon from '../ui/icons/FacebookIcon';
import LinkedInIcon from '../ui/icons/LinkedInIcon';
import elementLogo from '../ui/images/5thElementLogo.png';
import elementLogoMobile from '../ui/images/5thElementLogoMobile.png';
import footerGraphics from '../ui/images/FooterGraphics.png';
import footerGraphicsMobile from '../ui/images/FooterGraphicsMobile.png';
import Waves from '../ui/icons/Waves';

const Footer = () => {
	const { typography, breakpoints } = useTheme();

	return (
		<Box
			position={'relative'}
			component={'footer'}
			zIndex={103}
		>
			<Box
				display={'none'}
				position={'absolute'}
				right={'0'}
				top={'-60px'}
				zIndex={3}
				sx={{
					[breakpoints.down('sm')]: {
						display: 'block',
					},
				}}
			>
				<Box
					component={'img'}
					src={footerGraphics}
					alt={''}
					sx={{
						width: '140px',
						transform: 'scaleX(-1)',
					}}
				/>
			</Box>
			<Box
				width={'100%'}
				position={'absolute'}
				top={'-40px'}
				sx={{
					'& svg': {
						width: '100%',
						height: '100%',
					},
					[breakpoints.down('sm')]: {
						top: '-20px',
					},
				}}
			>
				<Waves color={'#fff'} />
			</Box>
			<Grid
				item
				zIndex={3}
				mr={'80px'}
				position={'relative'}
				height={'400px'}
				mb={'40px'}
				sx={{
					[breakpoints.down('lg')]: {
						height: 'auto',
						mb: '0px',
					},
					[breakpoints.down('md')]: {
						mr: '20px',
					},
					[breakpoints.down('sm')]: {
						mx: '20px',
					},
				}}
			>
				<Grid
					display={'flex'}
					m={'50px 80px'}
					justifyContent={'space-between'}
					sx={{
						[breakpoints.down('lg')]: {
							m: '40px',
							mb: '0px',
						},
						[breakpoints.down('md')]: {
							mx: '0',
							gap: '24px',
							ml: '20px',
						},
						[breakpoints.down('sm')]: {
							ml: '0',
							flexDirection: 'column',
						},
					}}
				>
					<Box
						width={'50%'}
						sx={{
							[breakpoints.down('lg')]: {
								width: 'auto',
							},
						}}
					>
						<Box
							component={'img'}
							src={elementLogo}
							alt={''}
							sx={{
								[breakpoints.down('md')]: {
									width: '122px',
								},
								[breakpoints.down('sm')]: {
									width: '148px',
								},
							}}
						/>
					</Box>
					<Box
						width={'50%'}
						display={'flex'}
						gap={'24px'}
						justifyContent={'space-between'}
						sx={{
							[breakpoints.down('lg')]: {
								width: 'auto',
								ml: '40px',
								gap: '36px',
							},
							[breakpoints.down('md')]: {
								ml: '0',
								gap: '16px',
							},
							[breakpoints.down('sm')]: {
								flexDirection: 'column',
							},
						}}
					>
						<Box
							display={'flex'}
							flexDirection={'column'}
							gap={'10px'}
							sx={{
								'& *': {
									whiteSpace: 'nowrap',
								},
								[breakpoints.down('sm')]: {
									gap: '0px',
								},
							}}
						>
							<Typography
								sx={{
									mb: '24px',
									color: '#1D1D1D',
									fontSize: '16px',
									fontWeight: '700',
									lineHeight: '30px',
									letterSpacing: '-0.24px',
									[breakpoints.down('md')]: {
										mb: '8px',
									},
								}}
							>
								Kontakt
							</Typography>
							<Typography
								sx={{
									color: '#1D1D1D',
									fontSize: '16px',
									fontWeight: '400',
									letterSpacing: '-0.24px',
									[breakpoints.down('lg')]: {
										fontSize: '14px',
									},
									[breakpoints.down('md')]: {
										fontSize: '12px',
									},
									[breakpoints.down('sm')]: {
										fontSize: '14px',
									},
								}}
							>
								tel. 518 762 900
							</Typography>
							<Typography
								sx={{
									textDecoration: 'underline',
									color: '#1D1D1D',
									fontSize: '16px',
									fontWeight: '400',
									letterSpacing: '-0.24px',
									[breakpoints.down('lg')]: {
										fontSize: '14px',
									},
									[breakpoints.down('md')]: {
										fontSize: '12px',
									},
									[breakpoints.down('sm')]: {
										fontSize: '14px',
									},
								}}
							>
								kontakt@za-miastem.pl
							</Typography>
						</Box>
						<Box
							display={'flex'}
							flexDirection={'column'}
							gap={'24px'}
							sx={{
								'& *': {
									whiteSpace: 'nowrap',
								},
								[breakpoints.down('md')]: {
									gap: '8px',
								},
							}}
						>
							<Typography
								sx={{
									color: '#1D1D1D',
									fontSize: '16px',
									fontWeight: '700',
									letterSpacing: '-0.24px',
								}}
							>
								Dane firmy
							</Typography>
							<Typography
								sx={{
									color: '#1D1D1D',
									fontSize: '16px',
									fontWeight: '400',
									letterSpacing: '-0.24px',
									[breakpoints.down('lg')]: {
										fontSize: '14px',
									},
									[breakpoints.down('md')]: {
										fontSize: '12px',
									},
									[breakpoints.down('sm')]: {
										fontSize: '14px',
									},
								}}
							>
								BROWAR ZA MIASTEM Sp. z o.o. SKA
								<br />
								ul. Południowa 14, 62-080 Rumianek
							</Typography>
							<Typography
								sx={{
									color: '#1D1D1D',
									fontSize: '16px',
									fontWeight: '400',
									letterSpacing: '-0.24px',
									[breakpoints.down('lg')]: {
										fontSize: '14px',
									},
									[breakpoints.down('md')]: {
										fontSize: '12px',
									},
									[breakpoints.down('sm')]: {
										fontSize: '14px',
									},
								}}
							>
								KRS: 0000609600 NIP: 7811925913
								<br />
								Sąd Rejonowy Poznań- Nowe Miasto
								<br />i Wilda w Poznaniu
							</Typography>
							<Typography
								sx={{
									color: '#1D1D1D',
									fontSize: '16px',
									fontWeight: '400',
									letterSpacing: '-0.24px',
									[breakpoints.down('lg')]: {
										fontSize: '14px',
									},
									[breakpoints.down('md')]: {
										fontSize: '12px',
									},
									[breakpoints.down('sm')]: {
										fontSize: '14px',
									},
								}}
							>
								Kapitał zakładowy Spółki(w całości opłacony):
								<br />1 465 85,30 zł
							</Typography>
						</Box>
					</Box>
				</Grid>
				<Grid
					position={'relative'}
					top={'-200px'}
					display={'flex'}
					alignItems={'flex-end'}
					sx={{
						[breakpoints.down('lg')]: {
							top: '-100px',
						},
						[breakpoints.down('md')]: {
							top: '-50px',
						},
						[breakpoints.down('sm')]: {
							top: '0px',
							mt: '24px',
						},
					}}
				>
					<Box
						component={'img'}
						src={footerGraphics}
						alt={''}
						alignSelf={'flex-start'}
						sx={{
							[breakpoints.down('md')]: {
								display: 'none',
							},
						}}
					/>
					<Box
						component={'img'}
						src={footerGraphicsMobile}
						alt={''}
						alignSelf={'flex-start'}
						display={'none'}
						sx={{
							[breakpoints.down('md')]: {
								display: 'block',
							},
							[breakpoints.down('sm')]: {
								display: 'none',
							},
						}}
					/>
					<Grid
						display={'flex'}
						flexDirection={'column'}
						gap={'24px'}
						width={'100%'}
					>
						<Box
							component={'nav'}
							display={'flex'}
							justifyContent={'space-between'}
							alignItems={'flex-start'}
							width={'45%'}
							gap={'8px'}
							sx={{
								'& a': {
									color: '#1d1d1d',
									textDecoration: 'none',
									fontSize: '16px',
									fontWeight: '700',
									lineHeight: '24px',
									letterSpacing: '-0.24px',
									textAlign: 'center',
									cursor: 'pointer',
								},
								[breakpoints.down('lg')]: {
									width: 'auto',
								},
								[breakpoints.down('sm')]: {
									flexWrap: 'wrap',
									justifyContent: 'flex-start',
									gap: '24px',
									rowGap: '8px',
								},
							}}
						>
							<Link
								href={'https://za-miastem.pl'}
								target={'_blank'}
							>
								Browar za Miastem
							</Link>
						</Box>
						<Divider
							sx={{
								borderColor: '#5f5f5f',
								bgcolor: '#5f5f5f',
							}}
						/>
						<Grid
							display={'flex'}
							justifyContent={'space-between'}
							sx={{
								[breakpoints.down('sm')]: {
									flexDirection: 'column',
									alignItems: 'center',
									gap: '16px',
									mb: '24px',
								},
							}}
						>
							<Box
								display={'flex'}
								alignItems={'flex-start'}
								gap={'16px'}
							>
								<Link
									href={'https://www.facebook.com/BrowarZaMiastem/'}
									target={'_blank'}
								>
									<FacebookIcon />
								</Link>
								<Link
									href={'https://www.instagram.com/browarzamiastem/'}
									target={'_blank'}
								>
									<LinkedInIcon />
								</Link>
							</Box>
							<Typography
								color={'#1d1d1d'}
								textAlign={'center'}
								fontSize={'15px'}
								fontWeight={'400'}
								lineHeight={'24px'}
								letterSpacing={'-0.225px'}
								fontFamily={typography.fontFamily}
							>
								Wszelkie prawa zastrzeżone © 2023 - Browar za Miastem
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
};

export default Footer;
